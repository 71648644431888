import React, { useState, useEffect } from 'react'
import Divider from 'shared/Divider/Divider'
import { useSelector } from 'react-redux'
import { Position, Icon, Alignment, Button, Tag, ButtonGroup, InputGroup, Switch } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import groupBy from 'lodash/groupBy'
import styles from '../roster/CrewMember/Activity/Activity.module.scss'
import styles2 from './Filter.module.scss'
import Search from 'shared/search'
import LoadingButton from 'shared/LoadingButton'
import MultiSearchDropdown from 'shared/SearchDropdown/MultiSearchDropdown'
import { DateTime } from 'luxon'
import { changeFilter, toggleExcludedCrewmemberFromUpload } from '../actions'
import store from 'store'
import profileApi from 'profile/api'
import documentApi from '../api'
import { PubState, PubEventType } from '../actions'
import { useNavigate } from 'react-router-dom'

const makeUniqueArray = (arr, field, field2) => {
  return [
    ...new Set(
      [].concat
        .apply(
          [],
          arr.map((d) => d[field])
        )
        .map((d) => d[field2])
    ),
  ].filter((d) => d !== null)
}

const getShortMime = (type) => {
  switch (type) {
    case 'application/json':
      return 'JSON'
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'XLSX'
    case 'application/vnd.ms-excel':
      return 'XLS'
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'DOCX'
    case 'application/msword':
      return 'DOC'
    case 'application/pdf':
      return 'PDF'
    case 'application/zip':
      return 'ZIP'
    case 'text/plain':
      return 'TXT'
    case 'text/csv':
      return 'CSV'
    default:
      return 'FILE'
  }
}
export const LegacyDownloadListItem = ({ name, filename, last_modified_at, description, onClick, mime_type }) => (
  <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: 5 }}>
    <span style={{ opacity: 0.75, fontSize: 13 }}>{name}</span>
    <span style={{ marginLeft: 'auto' }}>
      {description && (
        <Tooltip2
          position={Position.LEFT}
          usePortal={false}
          content={
            <div style={{ width: '100%', fontSize: 13 }}>
              <div style={{ marginBottom: 5 }}>{description}</div>

              {filename && <div style={{ fontSize: 11, opacity: 0.5 }}>{filename}</div>}
              {last_modified_at && (
                <div style={{ fontSize: 11, opacity: 0.5 }}>
                  {DateTime.fromISO(last_modified_at).toFormat('yyyy-MM-dd HH:mm')}
                </div>
              )}
            </div>
          }>
          <Icon icon="small-info-sign" style={{ marginLeft: 5, opacity: 0.6 }} />
        </Tooltip2>
      )}

      <Tag interactive style={{ marginLeft: 5, width: 50, textAlign: 'center' }} onClick={onClick}>
        {getShortMime(mime_type)}
      </Tag>
    </span>
  </div>
)

export const FileDownloadListItem = ({ name, filename, last_modified_at, description, id, mime_type }) => (
  <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: 5 }}>
    <span style={{ opacity: 0.75, fontSize: 13 }}>{name}</span>
    <span style={{ marginLeft: 'auto' }}>
      {description && (
        <Tooltip2
          position={Position.LEFT}
          usePortal={false}
          content={
            <div style={{ width: '100%', fontSize: 13 }}>
              <div style={{ marginBottom: 5 }}>{description}</div>

              <div style={{ fontSize: 11, opacity: 0.5 }}>{filename}</div>
              <div style={{ fontSize: 11, opacity: 0.5 }}>
                {DateTime.fromISO(last_modified_at).toFormat('yyyy-MM-dd HH:mm')}
              </div>
            </div>
          }>
          <Icon icon="small-info-sign" style={{ marginLeft: 5, opacity: 0.6 }} />
        </Tooltip2>
      )}

      <Tag
        interactive
        style={{ marginLeft: 5, width: 50, textAlign: 'center' }}
        onClick={() => documentApi.downloadDocument(id)}>
        {getShortMime(mime_type)}
      </Tag>
    </span>
  </div>
)

const Filter = (props) => {
  const navigate = useNavigate()
  const filterStandby = useSelector((state) =>
    state?.user?.profile?.preferences?.filters?.standby === undefined
      ? true
      : state?.user?.profile?.preferences?.filters?.standby
  )
  const displayAirportTimes = useSelector((state) => state?.user?.profile?.preferences?.filters?.displayAirportTimes)
  const displayDutyTimes = useSelector((state) => state?.user?.profile?.preferences?.filters?.displayDutyTimes)
  const profile = useSelector((state) => state.user.profile)
  const [preferences, setPreferences] = useState(profile.preferences)

  const filter = useSelector((state) => state.rosters.filter)
  const [filterDate, setFilterDate] = useState([])
  const activeContext = useSelector((state) => state.user.profile.active_context)
  let { activePublication, data, allIssues, issues } = props

  const rosterData = data
  const {
    toggleSplitPreAssigned,
    displaySafeCare,

    displayIssues,
    filterScore,
    searchFlight,
    searchFlightNumber,
    searchActivityCode,
    searchPlane,
    searchSpecialCode,
    searchPlCode,
    searchHomeBase,
    toggleExcludedCrewmembers,
    searchSecondBase,
    includeSecondBase,
    searchFunction,
    searchQualifications,
    searchAirportQualifications,

    filterSeverityBlocking,
    filterSeverityError,
    filterSeverityWarning,
    filterIssueType,
    filterIgnoredPreAssignedPairingIssues,
  } = filter
  var { filterTypeOfActivity } = filter

  var flightNumbers = rosterData
    .map((d) => d.crewFunction === 'Plane' && d.activities.map((a) => a.flightNumber))
    .flat()

  var specialFunctionsAtStartOfPublication = [
    ...new Set(
      rosterData
        .filter((d) => d.crewFunction !== 'Plane' && d.specialFunctionsAtStartOfPublication)
        .map((d) => d.specialFunctionsAtStartOfPublication)
        .flat()
    ),
  ].sort((a, b) => (a > b ? 1 : -1))

  flightNumbers = [...new Set(flightNumbers.filter((x) => x))].sort((a, b) => a.slice(2, 10) - b.slice(2, 10)) || []

  var activityCodes = rosterData.map((d) => d.activities.map((a) => a.standbyCode || a.activityCode)).flat()
  activityCodes = [...new Set(activityCodes.filter((x) => x))].sort((a, b) => a.slice(2, 10) - b.slice(2, 10)) || []

  const handleActivityTypeClick = (d) => {
    let newFilter = filterTypeOfActivity.slice()
    if (filterTypeOfActivity.includes(d)) {
      newFilter = filterTypeOfActivity.filter((e) => {
        return e !== d
      })
    } else {
      newFilter.push(d)
    }
    store.dispatch(
      changeFilter({
        filterTypeOfActivity: newFilter,
      })
    )
  }

  const issuesTypes = Object.keys(groupBy(allIssues, 'issueType'))

  return (
    <>
      <Divider text={`Crew (${rosterData.filter((d) => d.crewFunction !== 'Plane')?.length})`} />
      <MultiSearchDropdown
        style={{
          marginBottom: 10,
          width: '100%',
        }}
        value={searchActivityCode}
        placeholder="Activity Code"
        items={activityCodes.map((d) => {
          return {
            key: d,
            value: d,
            text: d,
          }
        })}
        onChange={(value) => {
          store.dispatch(
            changeFilter({
              searchActivityCode: value,
            })
          )
        }}
      />
      <MultiSearchDropdown
        value={searchPlCode}
        fill
        style={{
          marginBottom: 10,
        }}
        placeholder="Employee Code"
        items={rosterData
          .filter((d) => d.crewFunction !== 'Plane')
          .map((d) => {
            return {
              key: d.employeeCode,
              value: d.employeeCode,
              text: `${d.employeeCode} - ${d.personalID}`,
            }
          })}
        onChange={(value) => {
          store.dispatch(
            changeFilter({
              searchPlCode: value,
            })
          )
        }}
      />
      <MultiSearchDropdown
        value={searchFunction}
        fill
        style={{
          marginBottom: 10,
        }}
        placeholder="Crew Function"
        items={[...new Set(rosterData.map((d) => d.crewFunction))]
          .filter((d) => d !== 'Plane')
          .map((d) => {
            return {
              key: d,
              value: d,
              text: d,
            }
          })}
        onChange={(value) =>
          store.dispatch(
            changeFilter({
              searchFunction: value,
            })
          )
        }
      />
      <div style={{ display: 'flex' }}>
        <MultiSearchDropdown
          value={searchHomeBase}
          style={{
            marginBottom: 10,
            color: 'white',
            width: '50%',
          }}
          placeholder="Home Base"
          items={[...new Set(rosterData.map((d) => d.homeBase))]
            .filter((d) => Boolean(d)) // Get rid of empty strings for dropdown items
            .map((d) => {
              return {
                key: d,
                value: d,
                text: d,
              }
            })}
          onChange={(value) =>
            store.dispatch(
              changeFilter({
                searchHomeBase: value,
              })
            )
          }
        />
        <MultiSearchDropdown
          value={searchSecondBase}
          style={{
            marginBottom: 10,
            color: 'white',
            width: '50%',
            paddingLeft: 3,
          }}
          placeholder="Second Base"
          items={[...new Set(rosterData.map((d) => d.homeBase))]
            .filter((d) => Boolean(d)) // Get rid of empty strings for dropdown items
            .map((d) => {
              return {
                key: d,
                value: d,
                text: d,
              }
            })}
          onChange={(value) => {
            store.dispatch(
              changeFilter({
                searchSecondBase: value,
              })
            )
          }}
        />
      </div>
      <MultiSearchDropdown
        value={searchSpecialCode}
        fill
        style={{
          marginBottom: 10,
        }}
        placeholder="Special Function"
        items={specialFunctionsAtStartOfPublication.map((d) => {
          return {
            key: d,
            value: d,
            text: d,
          }
        })}
        onChange={(value) =>
          store.dispatch(
            changeFilter({
              searchSpecialCode: value,
            })
          )
        }
      />
      <MultiSearchDropdown
        value={searchQualifications}
        fill
        style={{
          marginBottom: 10,
        }}
        placeholder="Aircraft Qualification"
        items={makeUniqueArray(rosterData, 'aircraftQualifications', 'qualificationCode').map((d) => {
          return {
            key: d,
            value: d,
            text: d,
          }
        })}
        onChange={(value) =>
          store.dispatch(
            changeFilter({
              searchQualifications: value,
            })
          )
        }
      />
      <MultiSearchDropdown
        value={searchAirportQualifications}
        fill
        style={{
          marginBottom: 10,
        }}
        placeholder="Airport Qualification"
        items={[
          ...new Set(
            [].concat
              .apply(
                [],
                rosterData.map((d) => d.airportQualifications)
              )
              .map((d) => d.airport)
          ),
        ].map((d) => {
          return {
            key: d,
            value: d,
            text: d,
          }
        })}
        onChange={(value) =>
          store.dispatch(
            changeFilter({
              searchAirportQualifications: value,
            })
          )
        }
      />
      <div style={{ display: 'flex' }}>
        <span
          style={{
            width: '50%',
            marginLeft: 10,
            float: 'left',
          }}>
          {['Flight', 'IataFlight', 'Deadhead', 'Weekend', 'Standby', 'GroundDuty'].map((d, i) => (
            <div
              style={{
                height: 19,
                cursor: 'pointer',
                color: filterTypeOfActivity.includes(d) ? 'white' : '#999',
              }}
              onClick={() => {
                handleActivityTypeClick(d)
              }}
              key={i}>
              <span
                className={[styles2.legendColor, styles[d]].join(' ')}
                style={{
                  marginRight: 10,
                  border: '1.2px solid #eee',
                  verticalAlign: 'middle',
                }}></span>
              <span
                style={{
                  lineHeight: '19px',
                  fontSize: 14,
                  verticalAlign: 'middle',
                }}>
                {d}
              </span>
            </div>
          ))}
        </span>
        <span
          style={{
            width: '50%',
            float: 'right',
            marginRight: 10,
          }}>
          {['Hotel', 'PreAssigned', 'Taxi', 'TaxiOwnMeans', 'BaseSwitch'].map((d, i) => (
            <div
              style={{
                height: 19,
                cursor: 'pointer',
                color: filterTypeOfActivity.includes(d) ? 'white' : '#999',
              }}
              onClick={() => handleActivityTypeClick(d)}
              key={i}>
              <span
                className={[styles2.legendColor, styles[d]].join(' ')}
                style={{
                  marginLeft: 10,
                  float: 'right',
                  border: '1.2px solid #eee',
                  verticalAlign: 'middle',
                  marginTop: 4,
                }}></span>
              <span
                style={{
                  lineHeight: '19px',
                  fontSize: 14,
                  float: 'right',
                  verticalAlign: 'middle',
                }}>
                {d}
              </span>
            </div>
          ))}
        </span>
      </div>
      <Divider text="Crew issues" />
      <Switch
        className={styles2.filter}
        label="Inefficient preassignments"
        alignIndicator={Alignment.RIGHT}
        checked={filterIgnoredPreAssignedPairingIssues}
        onChange={() =>
          store.dispatch(
            changeFilter({
              filterIgnoredPreAssignedPairingIssues: !filterIgnoredPreAssignedPairingIssues,
            })
          )
        }
      />
      <Switch
        className={styles2.filter}
        label="Split pre-assigned/scheduled"
        alignIndicator={Alignment.RIGHT}
        checked={toggleSplitPreAssigned}
        onChange={() =>
          store.dispatch(
            changeFilter({
              toggleSplitPreAssigned: !toggleSplitPreAssigned,
            })
          )
        }
      />
      <Divider
        text={`Aircraft (${rosterData.filter((d) => d.crewFunction === 'Plane')?.length})`}
        style={{ marginTop: 20 }}
      />
      <MultiSearchDropdown
        value={searchPlane}
        fill
        style={{
          marginBottom: 10,
        }}
        placeholder="Aircraft Registration"
        items={rosterData
          .filter((d) => d.crewFunction === 'Plane')
          .map((d) => {
            return {
              key: d.employeeCode,
              value: d.employeeCode,
              text: `${d.employeeCode}`,
            }
          })}
        onChange={(value) => {
          store.dispatch(
            changeFilter({
              searchPlane: value,
            })
          )
        }}
      />
      <Divider text={`Common`} style={{ marginTop: 20 }} />
      <MultiSearchDropdown
        style={{
          marginBottom: 10,
          width: '100%',
        }}
        value={searchFlightNumber}
        placeholder="Flight Number"
        items={flightNumbers.map((d) => {
          return {
            key: d,
            value: d,
            text: d,
          }
        })}
        onChange={(value) => {
          store.dispatch(
            changeFilter({
              searchFlightNumber: value,
            })
          )
        }}
      />
      <Search
        value={searchFlight}
        fill
        style={{
          marginBottom: 10,
        }}
        placeholder="Departure - Destination"
        onChange={(value) => {
          var regex1 = /^[a-zA-Z]{0,3}$/
          var regex2 = /^[a-zA-Z]{3}-'$/
          var regex3 = /^[a-zA-Z]{3}-[a-zA-Z]{0,3}$/
          var regex4 = /^-[a-zA-Z]{0,3}$/

          if (!regex1.test(value) && !regex2.test(value) && !regex3.test(value) && !regex4.test(value)) return false
          store.dispatch(
            changeFilter({
              searchFlight: value,
            })
          )
        }}
      />
      <MultiSearchDropdown
        disabled={filterIgnoredPreAssignedPairingIssues}
        value={filterIssueType}
        fill
        style={{
          marginBottom: 10,
        }}
        placeholder="Issue Type"
        items={issuesTypes.map((d) => {
          return {
            key: d,
            value: d,
            text: d,
          }
        })}
        onChange={(value) =>
          store.dispatch(
            changeFilter({
              filterIssueType: value,
            })
          )
        }
      />
      <InputGroup
        //  onChange={this.handleTagChange}
        placeholder="Severity"
        rightElement={
          <ButtonGroup minimal>
            <Button
              icon="info-sign"
              intent={filterSeverityWarning ? 'primary' : undefined}
              active={filterSeverityWarning}
              onClick={() =>
                store.dispatch(
                  changeFilter({
                    filterSeverityWarning: !filterSeverityWarning,
                  })
                )
              }
            />
            <Button
              icon="warning-sign"
              intent={filterSeverityError ? 'warning' : undefined}
              className={styles2.button}
              active={filterSeverityError}
              onClick={() =>
                store.dispatch(
                  changeFilter({
                    filterSeverityError: !filterSeverityError,
                  })
                )
              }
            />
            <Button
              icon="error"
              intent={filterSeverityBlocking ? 'danger' : undefined}
              active={filterSeverityBlocking}
              onClick={() =>
                store.dispatch(
                  changeFilter({
                    filterSeverityBlocking: !filterSeverityBlocking,
                  })
                )
              }
            />
          </ButtonGroup>
        }
      />
      <Divider text="Show/Hide" style={{ marginTop: 20, marginBottom: 10 }} />
      <Switch
        className={styles2.filter}
        label="Standbys"
        alignIndicator={Alignment.RIGHT}
        checked={filterStandby}
        onChange={() => {
          profileApi.saveMyProfile({
            ...profile,
            preferences: {
              ...preferences,
              filters: { standby: !filterStandby },
            },
          })
        }}
      />
      <div style={{ display: 'block', position: 'relative' }}>
        <Switch
          className={styles2.filter}
          label="Timestamps"
          alignIndicator={Alignment.RIGHT}
          checked={displayDutyTimes}
          onChange={() =>
            profileApi.saveMyProfile({
              ...profile,
              preferences: {
                ...preferences,
                filters: { displayAirportTimes: displayAirportTimes, displayDutyTimes: !displayDutyTimes },
              },
            })
          }
        />
        {displayDutyTimes && (
          <div style={{ position: 'absolute', top: -2, right: 40 }}>
            <Tag
              round
              interactive
              intent={!displayAirportTimes ? 'primary' : undefined}
              onClick={() =>
                profileApi.saveMyProfile({
                  ...profile,
                  preferences: {
                    ...preferences,
                    filters: { displayDutyTimes: displayDutyTimes, displayAirportTimes: false },
                  },
                })
              }>
              sign in/out
            </Tag>
            <Tag
              style={{ marginLeft: 5 }}
              round
              interactive
              intent={displayAirportTimes ? 'primary' : undefined}
              onClick={() =>
                profileApi.saveMyProfile({
                  ...profile,
                  preferences: {
                    ...preferences,
                    filters: { displayDutyTimes: displayDutyTimes, displayAirportTimes: true },
                  },
                })
              }>
              dep/arr
            </Tag>
          </div>
        )}
        {props.additionalToggles}
      </div>
    </>
  )
}
export default Filter
