import React from 'react'
import Steps from 'shared/Steps'
import { PubState, storeActivePublication } from './actions'
import { Routes, Navigate, matchPath, useMatch, useLocation, useParams, useNavigate } from 'react-router-dom'

const determineWizardState = (state) => {
  switch (state) {
    case PubState.INIT:
    case PubState.CONFIG_DATA_READY:
      return 0
    case PubState.IDPS_EXPORT_WAITING:
    case PubState.IDPS_EXPORT_READY:
      return 1
    case PubState.FCOV_CALC_WAITING:
    case PubState.FCOV_CALC_READY:
      return 2
    case PubState.ROSTER_CALC_WAITING:
    case PubState.ROSTER_CALC_ERRORS:
    case PubState.ROSTER_CALC_READY:
      return 3
    case PubState.IDPS_IMPORT_WAITING:
    case PubState.IDPS_IMPORT_READY:
      return 4
    case PubState.ABORTED:
    case PubState.END:
      return 5
    default:
      return ''
  }
}

const determineInProgressWizardState = (state) => {
  switch (state) {
    case PubState.IDPS_EXPORT_WAITING:
      return 1
    case PubState.FCOV_CALC_WAITING:
      return 2
    case PubState.ROSTER_CALC_WAITING:
      return 3
    case PubState.IDPS_IMPORT_WAITING:
      return 4

    default:
      return ''
  }
}
const determineErrorWizardState = (state) => {
  switch (state) {
    case PubState.ROSTER_CALC_ERRORS:
      return 3
    default:
      return ''
  }
}
const getCurrentState = (urlTarget) => {
  if (urlTarget === 'data') {
    return 0
  }
  if (urlTarget === 'idpsexport') {
    return 1
  }
  if (urlTarget === 'coverage') {
    return 2
  }
  if (urlTarget === 'roster') {
    return 3
  }
  if (urlTarget === 'idpsimport') {
    return 4
  }
  if (urlTarget === 'completed') {
    return 5
  }
}
const PublicationSteps = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  let res = matchPath('/publications/:name/:urlTarget/:employee/*', location.pathname)
  if (!res) res = matchPath('/publications/:name/:urlTarget', location.pathname)
  if (!res) res = matchPath('/publications/:name', location.pathname)

  const urlTarget = res?.params?.urlTarget
  const index = determineWizardState(props.publication?.state)
  const inprogressIndex = determineInProgressWizardState(props.publication?.state)
  const selectedIndex = getCurrentState(urlTarget)
  const { id } = props.publication
  return (
    <Steps
      current={index}
      tooltips={props.tooltips}
      selected={selectedIndex}
      active={inprogressIndex}
      items={[
        { title: 'Data', subtitle: '', link: `/publications/${id}/data/` },
        { title: 'IDPS Export', subtitle: '', link: `/publications/${id}/idpsexport/` },
        { title: 'Coverage', subtitle: '', link: `/publications/${id}/coverage/` },
        { title: 'Roster', subtitle: '', link: `/publications/${id}/roster/` },
        { title: 'IDPS Import', subtitle: '', link: `/publications/${id}/idpsimport/` },
        {
          title: props.publication.archived_at
            ? 'Archived'
            : props.publication.state === PubState.ABORTED
            ? 'Aborted'
            : 'Completed',
          subtitle: '',
          error: props.publication.state === PubState.ABORTED,
          link: `/publications/${id}/completed/`,
        },
      ]}
    />
  )
}

export default React.memo(PublicationSteps)
