import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from '@blueprintjs/core'
import styles from './Steps.module.scss'
import { Tooltip2 } from '@blueprintjs/popover2'
import mainstyles from 'index.module.scss'

const Steps = (props) => {
  const [hoveredStep, setHoveredStep] = useState(null)
  const content = (item, i) => (
    <Link key={i} to={i <= props.current ? item?.link : undefined} className={mainstyles.hidestyle}>
      <span
        key={i}
        onMouseEnter={() => setHoveredStep(i)}
        onMouseLeave={() => setHoveredStep(null)}
        className={[
          styles.step,

          i == hoveredStep - 1 && i < props.current ? styles.beforeHoveredStep : undefined,
          i == hoveredStep && i <= props.current ? styles.hoveredStep : undefined,
          i == props.selected ? styles.selectedStep : undefined,
          i < props.current ? styles.enabledStep : undefined,
          i === props.current ? styles.inprogressStep : undefined,
        ].join(' ')}
        style={{
          display: 'flex',
          cursor: i <= props.current ? 'pointer' : 'default',
          alignItems: 'center',
          position: 'relative',
        }}>
        <span className={styles.bullet}>
          {i <= props.current && <span className={styles.expandingCircle} />}
          <span className={styles.bulletDot} />
          {i === props.active && <span className={styles.pulse} />}
        </span>
        {i < props.items.length && (
          <>
            {i < props.items.length - 1 && (
              <span
                className={styles.track1}
                style={{
                  flex: '0 0 auto',
                  height: 3,
                }}
              />
            )}
            <span className={styles.label}>
              <div className={[styles.mainLabel, props.items[i].error === true ? styles.errorLabel : ''].join(' ')}>
                {item.title}
              </div>
              <div className={styles.subLabel}>{item.subtitle}&nbsp;</div>
            </span>
            {i < props.items.length - 1 && (
              <span
                className={styles.track}
                style={{
                  marginLeft: 0,
                  marginRight: i === props.selected - 1 ? 5 : 0,
                  flex: 1,
                  height: 3,
                }}
              />
            )}
          </>
        )}
      </span>
    </Link>
  )
  return props.tooltips ? (
    <span className={styles.wrapper}>
      {props.items.map((item, i) => (
        <Tooltip2
          key={i}
          usePortal={true}
          portalContainer={document.getElementById('main')}
          portalClassName={styles.top}
          //disabled={true}
          // disabled={size.width > 300}
          content={item.title}>
          {content(item, i)}
        </Tooltip2>
      ))}
    </span>
  ) : (
    <span className={styles.wrapper}>{props.items.map(content)}</span>
  )
}

export default Steps
