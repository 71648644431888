import React, { useState, useEffect } from 'react'
import ErrorBoundary from 'log/ErrorBoundary'
import { intl } from 'i18n/IntlGlobalProvider'
import DataObject from './DataObject.js'
import styles from './Data.module.scss'
import {
  Position,
  FormGroup,
  Card,
  Callout,
  H5,
  H6,
  Tag,
  Icon,
  Classes,
  Button,
  Divider,
  Switch,
} from '@blueprintjs/core'
import Search from 'shared/search'
import CreateOrSaveDialog from './DataObjectItemDialog'
import TooltipButton from 'shared/TooltipButton'
import CurrencyDropdown from 'shared/CurrencyDropdown/CurrencyDropdown'
import TimezoneDropdown from 'shared/TimezoneDropdown/TimezoneDropdown'
import AirportDropdown from 'shared/AirportDropdown/AirportDropdown'
import { InputNumber, InputTimeRange, InputWeekdays, InputDuration, InputCost } from 'shared/Forms'
import { Tooltip2 } from '@blueprintjs/popover2'
const weekdays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

const newOption = { value: null, maxPassengers: null }

const newSlot = {
  from: '00:00',
  until: '24:00',
  daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
  duration: null,
  costs: [newOption],
}

const Taxis = ({ data, search, onClick }) => {
  if (data.payload.items?.length === 0)
    return (
      <Callout title="No items" style={{ margin: '20px auto', width: 300 }}>
        <Button
          style={{ marginBottom: 10 }}
          fill
          icon="plus"
          text="Add New Taxi"
          onClick={() => {
            onClick('new')
          }}
        />
      </Callout>
    )
  data.payload.items
  return data.payload.items?.map((item, key) => (
    <Card
      style={{
        display: search
          ? (item.departureAirport + '-' + item.arrivalAirport).toUpperCase().includes(search.toUpperCase())
            ? 'block'
            : 'none'
          : 'block',
      }}
      key={key}
      interactive={data.is_editable}
      elevation={1}
      className={styles.card}
      onClick={
        data.is_editable
          ? (e) => {
              e.stopPropagation()
              onClick(key)
            }
          : undefined
      }>
      <H5>
        {item.departureAirport} - {item.arrivalAirport}
      </H5>
      {item.schedules.length} Taxi&lsquo;s
    </Card>
  ))
}

const CreateNewTaxi = (props) => {
  const error =
    props.isOpen === 'new'
      ? props.validation_errors?.[props.dataObject.payload.items.length]
      : props.validation_errors?.[props.isOpen]
  const currency_info = props.configuration?.currency_info
  let availableCurrencies = []
  if (currency_info) {
    availableCurrencies = [currency_info?.default_currency, ...currency_info?.exchange_rates.map((d) => d.from)]
  }
  const newTaxi = {
    arrivalAirport: null,
    departureAirport: null,
    schedules: [newSlot],
    currency: currency_info?.default_currency,
    // timezone: 'UTC',
  }

  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    setTabIndex(0)
  }, [props.dataObject])

  const changeTaxi = (data, setData, field, value) => {
    let newSlot = { ...data.schedules[tabIndex] }
    newSlot[field] = value
    let newValues = [...data.schedules]
    newValues.splice(tabIndex, 1, newSlot)
    setData({ ...data, schedules: newValues })
  }

  const changeOption = (data, setData, optionIndex, field, value) => {
    let newSlot = JSON.parse(JSON.stringify(data.schedules[tabIndex]))
    newSlot.costs[optionIndex][field] = value
    let newValues = JSON.parse(JSON.stringify(data.schedules))
    newValues.splice(tabIndex, 1, newSlot)
    setData({ ...data, schedules: newValues })
  }

  return (
    <CreateOrSaveDialog
      style={{ minWidth: 650 }}
      usePortal={false}
      payload={props.dataObject.payload}
      id={props.dataObject.id}
      icon="taxi"
      title="Taxi"
      edit={props.isOpen >= 0 && props.isOpen !== 'new'}
      resetObject={newTaxi}
      onClose={props.onClose}
      onSave={props.onSave}
      isOpen={props.isOpen}>
      {(data, setData) => {
        return (
          <>
            <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>From</span>} inline>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <AirportDropdown
                  error={error?.departureAirport}
                  activeItem={data?.departureAirport}
                  onChange={(d) => setData({ ...data, departureAirport: d.iata })}
                />
                <span style={{ margin: '0px 10px' }}>to</span>
                <AirportDropdown
                  error={error?.arrivalAirport}
                  activeItem={data?.arrivalAirport}
                  onChange={(d) => setData({ ...data, arrivalAirport: d.iata })}
                />
              </div>
            </FormGroup>
            <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>Currency</span>} inline>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                <CurrencyDropdown
                  error={error?.currency}
                  activeItem={data?.currency}
                  items={availableCurrencies}
                  onChange={(d) => setData({ ...data, currency: d.key })}
                />
              </div>
            </FormGroup>

            <FormGroup contentClassName={styles.full} style={{ marginBottom: 0 }} inline>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: 600,
                }}>
                {data?.schedules?.map((d, i) => (
                  <Callout
                    intent={error?.schedules?.[i] ? 'danger' : undefined}
                    icon={null}
                    style={{
                      width: 65,
                      marginRight: 4,
                      fontWeight: i == tabIndex ? 'bold' : 'normal',
                      backgroundColor: i == tabIndex ? undefined : 'transparent',
                    }}
                    className={styles.tabButton}
                    key={i}
                    onClick={() => setTabIndex(i)}>
                    Taxi {i + 1}
                  </Callout>
                ))}
                <span style={{ flex: 1, marginLeft: 5 }}>
                  <TooltipButton
                    tooltip="Add Taxi"
                    minimal
                    icon="plus"
                    onClick={() => {
                      let newSchedules = JSON.parse(JSON.stringify(data?.schedules))
                      newSchedules.push(newSlot)
                      setData({ ...data, schedules: newSchedules })
                      setTabIndex(newSchedules.length - 1)
                    }}
                  />
                </span>

                <TooltipButton
                  tooltip="Delete Taxi"
                  minimal
                  icon="trash"
                  intent="danger"
                  onClick={() => {
                    let newValues = [...data?.schedules]
                    newValues.splice(tabIndex, 1)

                    setTabIndex(newValues.length - 1)

                    setData({ ...data, schedules: newValues })
                  }}
                />
              </div>
            </FormGroup>
            {tabIndex >= 0 && data?.schedules && data?.schedules.length > 0 && (
              <Callout
                style={{ maxHeight: 300, overflowY: 'auto' }}
                intent={error?.schedules?.[tabIndex] ? 'danger' : undefined}
                icon={null}>
                <FormGroup
                  contentClassName={styles.full}
                  label={<span style={{ width: 70, display: 'inline-block' }}>Weekdays</span>}
                  inline>
                  <InputWeekdays
                    value={data?.schedules[tabIndex]?.daysOfTheWeek}
                    onChange={(daysOfTheWeek) => {
                      let newSlot = { ...data.schedules[tabIndex] }
                      newSlot.daysOfTheWeek = daysOfTheWeek.slice()
                      let schedules = [...data.schedules]
                      schedules.splice(tabIndex, 1, newSlot)
                      setData({ ...data, schedules })
                    }}
                  />
                </FormGroup>
                <FormGroup
                  contentClassName={styles.full}
                  label={<span style={{ width: 70, display: 'inline-block' }}>Between</span>}
                  inline>
                  <InputTimeRange
                    error={{ from: error?.schedules?.[tabIndex]?.from, to: error?.schedules?.[tabIndex]?.until }}
                    from={data?.schedules?.[tabIndex]?.from}
                    to={data?.schedules?.[tabIndex]?.until}
                    onChange={(from, to) => {
                      let newSlot = { ...data.schedules[tabIndex] }
                      newSlot.from = from
                      newSlot.until = to
                      let schedules = [...data.schedules]
                      schedules.splice(tabIndex, 1, newSlot)
                      setData({ ...data, schedules })
                    }}
                  />
                </FormGroup>
                <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>Duration</span>} inline>
                  <InputDuration
                    validation_errors={error?.schedules?.[tabIndex]?.duration}
                    value={data?.schedules?.[tabIndex]?.duration}
                    onChange={(value) => changeTaxi(data, setData, 'duration', value)}
                  />
                </FormGroup>
                <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>Options</span>} inline>
                  {data?.schedules?.[tabIndex]?.costs.map((option, optionIndex) => (
                    <div
                      key={tabIndex + '' + optionIndex}
                      style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                      <InputCost
                        currency={data?.currency}
                        error={error?.schedules?.[tabIndex]?.costs?.[optionIndex]?.value}
                        placeholder="taxi cost"
                        value={option.value}
                        onChange={(value) => changeOption(data, setData, optionIndex, 'value', value)}
                      />

                      <InputNumber
                        strict
                        positive
                        error={error?.schedules?.[tabIndex]?.costs?.[optionIndex]?.maxPassengers}
                        placeholder="max. passengers"
                        leftIcon={<Icon icon="people" color="#ccc" />}
                        value={option.maxPassengers}
                        onChange={(value) => changeOption(data, setData, optionIndex, 'maxPassengers', value)}
                      />

                      {data?.schedules[tabIndex]?.costs?.length > 1 && (
                        <Button
                          minimal
                          icon="cross"
                          onClick={() => {
                            let newSlot = JSON.parse(JSON.stringify(data.schedules[tabIndex]))

                            newSlot.costs.splice(optionIndex, 1)
                            let newValues = JSON.parse(JSON.stringify(data.schedules))
                            newValues.splice(tabIndex, 1, newSlot)
                            setData({ ...data, schedules: newValues })
                          }}
                        />
                      )}
                    </div>
                  ))}
                  <Button
                    icon="plus"
                    text="Add taxi option"
                    onClick={() => {
                      let newSlot = JSON.parse(JSON.stringify(data.schedules[tabIndex]))
                      newSlot.costs.push(newOption)
                      let newValues = JSON.parse(JSON.stringify(data.schedules))
                      newValues.splice(tabIndex, 1, newSlot)

                      setData({ ...data, schedules: newValues })
                    }}
                  />
                </FormGroup>
                {error?.schedules?.[tabIndex]?.length && (
                  <Callout
                    intent="danger"
                    style={{ marginLeft: -15, backgroundColor: 'transparent', paddingBottom: 0 }}>
                    {error?.schedules?.[tabIndex].map((d) => (
                      <div>{d}</div>
                    ))}
                  </Callout>
                )}
              </Callout>
            )}
          </>
        )
      }}
    </CreateOrSaveDialog>
  )
}

const Comp = (props) => {
  const [search, setSearch] = useState('')
  const [dialog, setDialog] = useState(null)
  const { data } = props
  const { is_editable } = data

  return (
    <ErrorBoundary>
      <CreateNewTaxi
        configuration={props.configuration}
        validation_errors={props.data.validation_errors?.items}
        isOpen={dialog}
        dataObject={props.data}
        onSave={props.onSave}
        onClose={() => {
          setDialog(null)
        }}
      />

      <DataObject
        data={props.data}
        upload="json"
        title={intl.t('taxis')}
        search={
          <Search
            value={search}
            fill
            placeholder="Departure - Destination"
            onChange={(value) => {
              var regex1 = /^[a-zA-Z]{0,3}$/
              var regex2 = /^[a-zA-Z]{3}-'$/
              var regex3 = /^[a-zA-Z]{3}-[a-zA-Z]{0,3}$/
              var regex4 = /^-[a-zA-Z]{0,3}$/

              if (!regex1.test(value) && !regex2.test(value) && !regex3.test(value) && !regex4.test(value)) return false
              setSearch(value)
            }}
          />
        }
        onSave={props.onSave}
        // onSaveSuccess={setData}
        is_editable={is_editable}
        onAdd={() => setDialog('new')}
        nofilter>
        <Taxis data={props.data} search={search} onClick={setDialog} />
      </DataObject>
    </ErrorBoundary>
  )
}

export default Comp
