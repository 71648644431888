import React, { useEffect } from 'react'

import { Rows, Row, Cols, Col } from 'shared/PanelLayout/PanelLayout'
import { Icon, Button, Menu, NonIdealState, Navbar, Alignment } from '@blueprintjs/core'
import { Route, Routes, Navigate } from 'react-router-dom'
import PrivateRoute from 'PrivateRoute'
import Loader from 'shared/Loader/Loader'
import { useSelector } from 'react-redux'
import api from 'DataObjectEditor/api'
import styles from './Settings.module.scss'
import HeaderRow from 'shared/HeaderRow'
import { useNavigate, useLocation, useResolvedPath } from 'react-router-dom'
import { intl } from 'i18n/IntlGlobalProvider'
import DataObjectEditor from 'DataObjectEditor/DataObjectEditor'

const Settings = (props) => {
  const data = useSelector((state) => state.dataObjects.items)
  useEffect(() => {
    api.getAllContextDataObjects(activeContext)
  }, [])

  const { items, match, loading } = props
  const activeContext = useSelector((state) => state.user.profile.active_context)
  return (
    <Rows>
      <HeaderRow icon="cog" title={`Global Settings for Airline ${activeContext}`} z={50} />

      <Row>
        <DataObjectEditor global={true} data={data} onSave={api.putContextDataObject} />
      </Row>
    </Rows>
  )
}
export default Settings
