import React, { useState, useEffect, lazy, Suspense } from 'react'
import { HotkeysProvider } from '@blueprintjs/core'
import { Rows, Row } from 'shared/PanelLayout/PanelLayout'
import { Tag, Icon, EditableText, Tabs, Tab, Button, Spinner } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import {
  Link,
  Routes,
  Route,
  Navigate,
  matchPath,
  useMatch,
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom'
import store from 'store'
import { useSelector } from 'react-redux'
import api from './api'
import styles from './Publications.module.scss'
import Log from './log/index'
import { PubState, clearPublication } from './actions'
import HeaderRow from 'shared/HeaderRow'
import CreateNewPublicationDialog from './NewPublicationDialog'
import PublicationSteps from './PublicationSteps'
import { resetFilter } from './actions'
const Data = lazy(() => import('./data/Data'))
const IdpsExport = lazy(() => import('./IdpsExport/index'))
const Coverage = lazy(() => import('./coverage/index'))
const Publish = lazy(() => import('./publish/index'))
const Roster = lazy(() => import('./roster/Roster'))
const RosterResource = lazy(() => import('./../bidding/Roster'))

const determineWizardState = (state) => {
  switch (state) {
    case PubState.INIT:
    case PubState.CONFIG_DATA_READY:
    case PubState.ABORTED:
      return 'data'
    case PubState.IDPS_EXPORT_WAITING:
    case PubState.IDPS_EXPORT_READY:
      return 'idpsexport'
    case PubState.FCOV_CALC_WAITING:
    case PubState.FCOV_CALC_READY:
      return 'coverage'
    case PubState.ROSTER_CALC_WAITING:
    case PubState.ROSTER_CALC_ERRORS:
    case PubState.ROSTER_CALC_READY:
      return 'roster'
    case PubState.IDPS_IMPORT_WAITING:
    case PubState.IDPS_IMPORT_READY:
      return 'idpsimport'
    case PubState.END:
      return 'idpsimport'
    default:
      return 'data'
  }
}

const Publication = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const activePublication = useSelector((state) => state.rosters.activePublication)
  const loading = useSelector((state) => state.rosters.loading)
  const [isOpen, setIsOpen] = useState(null)
  const activeContext = useSelector((state) => state.user.profile.active_context)
  let res = matchPath('/publications/:name/:urlTarget/:employee/*', location.pathname)
  if (!res) res = matchPath('/publications/:name/:urlTarget', location.pathname)
  if (!res) res = matchPath('/publications/:name', location.pathname)
  console.log(activePublication)
  const { name, urlTarget, employee } = res.params

  useEffect(() => {
    store.dispatch(resetFilter())
    if (name !== activePublication.id) store.dispatch(clearPublication())
    api.getPublication(name)
  }, [name])

  useEffect(() => {
    if (!activePublication.id) return

    const object = activePublication.data_objects.find((d) => d.type === 'flex_roster_heatmap')
    if (object) api.getHeavyDataObject(activePublication.id, object.id)
  }, [activePublication.id])

  const getIcon = (loading, employee) => {
    return <Icon style={{ width: 30 }} icon="gantt-chart" />
  }
  const target = determineWizardState(activePublication.state)

  return (
    <>
      <CreateNewPublicationDialog
        isOpen={isOpen}
        resetObject={activePublication}
        data={activePublication}
        onClose={() => setIsOpen(null)}
        onSave={(data) =>
          api.updatePublication(
            data.id,
            {
              name: data.name,
              description: data.description,
              calculation_mode: data.calculation_mode,
              data_src: data.data_src,
              data_dst: data.data_dst,
              first_day: data.first_day,
              data_dst_roster_from: data.data_dst_roster_from,
              data_dst_roster_until: data.data_dst_roster_until,

              last_day: data.last_day,
              no_auto_trigger_event_types: data.no_auto_trigger_event_types,
            },
            true,
            activeContext
          )
        }
      />
      <Rows>
        <HeaderRow
          icon={activePublication.name ? 'edit' : undefined}
          disabled={activePublication.state === PubState.ABORTED}
          onClick={() => {
            setIsOpen(true)
          }}
          title={
            activePublication.name ? (
              <>
                <Tooltip2 content={activePublication.description}>{activePublication.name}</Tooltip2>{' '}
                <Tag minimal style={{ display: 'inline', marginBottom: 5, marginRight: 10 }}>
                  {activePublication.first_day}
                  <Icon icon="arrow-right" style={{ transform: 'scale(0.6)', marginLeft: 5, marginRight: 5 }}></Icon>
                  {activePublication.last_day}
                </Tag>
                {activePublication.calculation_mode === 'flexible' && (
                  <Tooltip2
                    content={`Calculation mode: ${activePublication.calculation_mode}`}
                    portalContainer={document.getElementById('main')}
                    placement="auto">
                    <Tag minimal intent="primary" style={{ display: 'inline', marginBottom: 5, marginRight: 10 }}>
                      FLX
                    </Tag>
                  </Tooltip2>
                )}
                {activePublication.status.message === 'error' && (
                  <Tooltip2
                    content={activePublication.status.detail}
                    intent="danger"
                    usePortal={true}
                    portalContainer={document.getElementById('main')}>
                    <Button icon="error" minimal intent="danger" />
                  </Tooltip2>
                )}
              </>
            ) : loading ? (
              'Loading...'
            ) : (
              ''
            )
          }
          z={1}>
          <PublicationSteps publication={activePublication} />
          {activePublication.events?.length > 0 && (
            <Link to="log">
              <Button icon="history" minimal />
            </Link>
          )}
        </HeaderRow>

        {activePublication.id && (
          <Row>
            <Suspense
              fallback={
                <div
                  style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Spinner />
                </div>
              }>
              <Routes>
                <Route path="/" element={<Navigate to={target} />} />

                <Route path="data/*" element={<Data />} />

                <Route path="idpsexport/*" element={<IdpsExport />} />

                <Route path="coverage/*" element={<Coverage />} />

                <Route path="roster" element={<Roster />} />

                <Route path="roster/:resource" element={<RosterResource />} />

                <Route path="idpsimport" element={<Publish />} />

                <Route path="log" element={<Log />} />

                <Route path="completed" element={<Publish />} />
              </Routes>
            </Suspense>
          </Row>
        )}

        {!activePublication.id && loading && (
          <Row>
            <div
              style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Spinner />
            </div>
          </Row>
        )}
      </Rows>
    </>
  )
}
export default Publication
