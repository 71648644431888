import React, { useState } from 'react'
import { Link, useNavigate, useLink, useResolvedPath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TooltipButton from 'shared/TooltipButton'
import PublicationSteps from './PublicationSteps'
import { Card, Icon, Tag } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import cardStyles from '../DataObjectEditor/Data.module.scss'
import { format } from 'shared/LuxonHelpers'
import api from './api'
import { iconRestore } from 'icons'

import PowerButton from 'shared/PowerButton'
import dashboardApi from 'dashboard/api'
import { AppToaster } from 'shared/Toaster/Toaster'
import mainstyles from 'index.module.scss'

const PublicationListItem = ({ item }) => {
  const navigate = useNavigate()
  const [hover, setHover] = useState(false)
  const reports = useSelector((state) => state.reports)
  const { publications, activeReports } = reports

  return (
    <Card
      className={cardStyles.card}
      style={{ margin: 10, backgroundColor: item.archived_at ? 'rgba(219, 55, 55, 0.1)' : undefined }}
      elevation={2}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      interactive>
      <div style={{ display: 'flex', height: 30, alignItems: 'center' }}>
        <span style={{ width: 40, flex: '0 0 auto' }}>
          <span className={cardStyles.showOnHover}>
            <TooltipButton
              tooltip="Create new publication from this configuration"
              minimal
              icon="duplicate"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                api.clonePublication(item.id)
              }}
            />
          </span>

          <span className={cardStyles.hideOnHover}>
            {
              <Icon
                style={{ marginLeft: 6 }}
                icon={item.archived_at ? 'trash' : item.data_src === 'manual_input' ? 'lab-test' : 'airplane'}
                intent={
                  item.archived_at
                    ? 'danger'
                    : item.data_src === 'manual_input'
                    ? 'warning'
                    : item.calculation_mode === 'flexible'
                    ? 'primary'
                    : undefined
                }
              />
            }
          </span>
        </span>

        <span style={{ flex: 1, minWidth: 0 }}>
          <Link to={item.id} className={mainstyles.hidestyle}>
            <div
              style={{
                fontSize: 17,
                fontWeight: 'bold',
                marginBottom: 6,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}>
              {item.name}
            </div>
            <div
              style={{
                color: '#aaa',
                fontSize: 12,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}>
              <Tag minimal style={{ display: 'inline', marginBottom: 5, marginRight: 10 }}>
                {item.first_day}
                <Icon icon="arrow-right" style={{ transform: 'scale(0.6)', marginLeft: 5, marginRight: 5 }}></Icon>
                {item.last_day}
              </Tag>

              {item.calculation_mode === 'flexible' && (
                <Tooltip2
                  content={`Calculation mode: ${item.calculation_mode}`}
                  portalContainer={document.getElementById('main')}
                  placement="auto">
                  <Tag minimal intent="primary" style={{ display: 'inline', marginBottom: 5, marginRight: 10 }}>
                    FLX
                  </Tag>
                </Tooltip2>
              )}

              {`Last modified: ${format(
                item.last_modified_at,
                'UTC',
                'yyyy-MM-dd HH:mm:ss'
              )} UTC by ${`${item.last_modified_by_name}`}`}
            </div>
          </Link>
        </span>
        <span style={{ flex: 0, display: 'flex' }}>
          {publications.findIndex((d) => d.publication === item.id) >= 0 &&
            activeReports.findIndex((d) => d.publication === item.id) === -1 && (
              <PowerButton
                minimal
                icon="dashboard"
                tooltip={'Add to dashboard'}
                onClick={(e) => {
                  e.stopPropagation()
                  const r = publications.find((d) => d.publication === item.id)

                  dashboardApi.storeCostReport(r)
                  AppToaster.show({
                    autoFocus: false,
                    intent: 'primary',
                    icon: 'tick',
                    timeout: 5000,
                    message: 'Publication added to dashboard',
                    action: {
                      onClick: () => {
                        navigate('/dashboard')
                      },

                      text: 'View dashboard',
                    },
                  })
                }}
              />
            )}
          {activeReports.findIndex((d) => d.publication === item.id) >= 0 && (
            <PowerButton
              minimal
              icon="dashboard"
              intent="success"
              tooltip={'Remove from dashboard'}
              onClick={(e) => {
                e.stopPropagation()
                const r = publications.find((d) => d.publication === item.id)

                dashboardApi.removeCostReport(r.id)
                AppToaster.show({
                  autoFocus: false,
                  intent: 'primary',
                  icon: 'tick',
                  timeout: 5000,
                  message: 'Publication removed from dashboard',
                  action: {
                    onClick: () => {
                      navigate('/dashboard')
                    },

                    text: 'View dashboard',
                  },
                })
              }}
            />
          )}
          <PublicationSteps publication={item} tooltips={hover} />
          <span style={{ width: 30 }}>
            {hover && !item.archived_at && (
              <PowerButton
                disable_on_click={1}
                tooltip="Archive"
                intent="danger"
                minimal
                icon={'trash'}
                onClick={(e) => {
                  e.stopPropagation()
                  api.archivePublication(item.id)
                }}
              />
            )}
            {hover && item.archived_at && (
              <PowerButton
                disable_on_click={1}
                tooltip="Restore"
                intent="primary"
                minimal
                icon={iconRestore}
                onClick={(e) => {
                  e.stopPropagation()
                  api.unarchivePublication(item.id)
                }}
              />
            )}
          </span>
        </span>
      </div>
    </Card>
  )
}
export default React.memo(PublicationListItem)
