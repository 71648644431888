import React, { useState, useEffect } from 'react'

import { InputGroup } from '@blueprintjs/core'
import Validate from './Validate'
import { Duration } from 'luxon'

function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index)
}

function isValidDurationISOFormat(inputString) {
  const pattern = /^P(?!$)(\d+Y)?(\d+M)?(\d+W)?(\d+D)?(T(?=\d)(\d+H)?(\d+M)?(\d+S)?)?$/
  return pattern.test(inputString)
}
function isValidDurationStringFormat(inputString) {
  const pattern = /^[0-9]{2}:[0-9]{2}$/
  return pattern.test(inputString)
}
function isValidDurationNumberFormat(inputString) {
  const pattern = /^[0-9]{4}$/
  return pattern.test(inputString)
}

const DurationInput = (props) => {
  const [internalValue, setInternalValue] = useState('')

  const { validation_errors, value, onChange, onInputNotValid } = props
  const [localError, setLocalError] = useState(validation_errors)
  useEffect(() => {
    if (value === null) setInternalValue('')
    if (isValidDurationNumberFormat(value)) setInternalValue(value.substr(0, 2) + ':' + value.substr(2))
    else if (isValidDurationStringFormat(value)) setInternalValue(value)
    else if (isValidDurationISOFormat(value)) {
      const v = Duration.fromISO(value).toObject()

      var hours = (v.hours || 0) + ''
      var mins = (v.minutes || 0) + ''

      setInternalValue(hours.padStart(2, '0') + ':' + mins.padStart(2, '0'))
    } else setInternalValue(value)
  }, [value])

  return (
    <Validate validation_errors={validation_errors}>
      <InputGroup
        {...props}
        autoFocus={true}
        value={internalValue || ''}
        intent={validation_errors || localError ? 'danger' : undefined}
        placeholder="eg. 00:15"
        onChange={({ target }) => {
          let v = target.value

          if (isValidDurationNumberFormat(v)) v = v.substr(0, 2) + ':' + v.substr(2)

          setInternalValue(v) //always set internalValue to whatever is typed`

          if (isValidDurationStringFormat(v)) {
            let value = Duration.fromISOTime(v).toISO()

            setLocalError(null)
            if (onInputNotValid) onInputNotValid(false)
            return onChange(value)
          } else if (isValidDurationISOFormat(v)) {
            setLocalError(null)
            if (onInputNotValid) onInputNotValid(false)
            return onChange(v)
          }

          setLocalError(true)
          if (onInputNotValid) onInputNotValid(true)
          return onChange(v)
        }}
      />
    </Validate>
  )
}

export default DurationInput
